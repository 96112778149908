import { Provider } from '@preact/prerender-data-provider';
import { Component, h } from 'preact';
import { Route, Router } from 'preact-router';
import Home from '../routes/home';

export default class App extends Component {
  render(props: any) {
    return (
      <Provider value={props}>
        <Router>
          <Route path="/" component={Home} />
        </Router>
      </Provider>
    );
  }
}
